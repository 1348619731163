'use client';

import type { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { styled, createGlobalStyle, css } from 'styled-components';
import type { ColorScheme, Gradient } from '@types';

type SectionProps = {
  /**
   * Sets the global color scheme when the section is in view.
   */
  colorScheme?: ColorScheme;
  /**
   * Modular content as children
   */
  children: ReactNode;
  /**
   * Should the section include vertical padding? Note that this adds quite a
   * bit of vertical space surrounding the modules within, so should be used
   * sparingly!
   *
   * default: false
   */
  isPadded?: boolean;
  /**
   * Optional prop primarily used for extending styles
   */
  className?: string;
  /**
   * Optional `id` prop for anchoring directly to the section
   */
  id?: string;
  /**
   * Which gradient should be applied?
   *
   * NOTE: We recommend choosing a colorScheme that has
   * a similar look as the gradient to avoid and weird
   * box-shadows on things like ImageCards
   *
   *
   * NOTE: at the moment, just the 'fourWayMoving' gradient has any animation.
   * The others sit statically on top of the section's underlying colorScheme.
   */
  gradient?: Gradient;
  /**
   * Sets the optional background image source. This should be an abolute path
   * to an image file. Preferably a jpg to keep the image file as small as possible.
   */
  backgroundImageSrc?: string;
};

const movingGradient = css`
  body {
    background-image: none;
  }

  body::before,
  body::after {
    display: inline-block;
  }
`;
const staticGradient = css`
  body::before,
  body::after {
    display: none;
  }
`;

const RootColor = createGlobalStyle<{ cs }>`
 ${({ cs, theme }) =>
   `
  :root {
    --global-color: ${theme.colorSchemes[cs].fg};
    --global-link-color: ${theme.colorSchemes[cs].link};
    --global-hover-color: ${theme.colorSchemes[cs].hover};
    --global-bg-color: ${theme.colorSchemes[cs].bg};
  }
 `}
`;

const RootGradient = createGlobalStyle<{ gradient; $hasMotion }>`
 ${({ gradient, theme, $hasMotion }) =>
   `
    :root {
      --gradient-base-ne: ${theme.gradients[gradient].ne};
      --gradient-base-se: ${theme.gradients[gradient].se};
      --gradient-base-sw: ${theme.gradients[gradient].sw};
      --gradient-base-nw: ${theme.gradients[gradient].nw};
    }
    ${$hasMotion ? movingGradient : staticGradient};
 `}
`;

const RootBackgroundImage = createGlobalStyle<{ imgSrc }>`
   ${({ imgSrc }) =>
     `
   :root {
    --global-bg-image: url(${imgSrc});
   }
 `}
`;

const SectionWrapper = styled.section<{
  $isPadded: boolean;
}>`
  ${({ $isPadded, theme }) =>
    $isPadded && `padding-block: ${theme.spacing[4]};`}
`;

const SectionContent = styled.div`
  margin-inline: auto;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  padding-inline: ${({ theme }) => theme.grid.margin.small};
  ${({ theme }) => theme.mq.minWidth.medium} {
    padding-inline: ${({ theme }) => theme.grid.margin.medium};
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    padding-inline: ${({ theme }) => theme.grid.margin.large};
  }
`;

// This is similar to what we do in ContentfulImage
const setOptimizedUrl = (src) => {
  const url = new URL(src);
  url.searchParams.set('fm', 'avif');
  url.searchParams.set('fit', 'fill');
  url.searchParams.set('w', '2160'); // Max of DVDs grid * 1.5
  url.searchParams.set('q', '70');
  return url.href;
};

/**
 * Primary sectioning component for a page providing content bounds for the
 * width *and* coloring.
 *
 * Each page should have __at least one__ Section component.
 */
const Section = ({
  isPadded = false,
  colorScheme,
  children,
  className,
  id,
  gradient = null,
  backgroundImageSrc = null,
}: SectionProps) => {
  const { ref, inView } = useInView({
    // Creates a horizontal line halfway from the bottom of the viewport that
    // will trigger an intersection at that the very point
    rootMargin: '-50% 0% -50% 0%',
  });

  const optimizedUrl = backgroundImageSrc
    ? setOptimizedUrl(backgroundImageSrc)
    : null;

  const hasMotion = gradient === 'fourWayMoving' || gradient == 'blueGreen';

  return (
    <SectionWrapper
      ref={ref}
      $isPadded={isPadded}
      className={className}
      id={id}
    >
      {inView && colorScheme ? <RootColor cs={colorScheme} /> : null}
      {inView && gradient ? (
        <RootGradient gradient={gradient} $hasMotion={hasMotion} />
      ) : null}
      {inView && backgroundImageSrc ? (
        <RootBackgroundImage imgSrc={optimizedUrl} />
      ) : null}
      <SectionContent>{children}</SectionContent>
    </SectionWrapper>
  );
};

export default Section;
